
import { Vue, Component } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import { PermissionsQuery } from "@/gql"
import AddUserForm from "@/components/forms/AddUserForm.vue"

@Component({ components: { AppPage, AddUserForm } })
export default class Permissions extends Vue {
  readonly PermissionsQuery = PermissionsQuery

  readonly headers = [
    { text: "Name", value: "name", sortable: true },
    { text: "Slug", value: "slug", sortable: true },
    { text: "Description", value: "description", sortable: true },
  ]

  showAddUserDialog = false
}
